const { createApp } = Vue

createApp({
  data() {
    return {
      dragDone: true,
      positions:{
        clientX: 0,
        clientY: 0,
        movementY: 0,
        movementX: 0
      },
      scale: {
        max: 2.5,      
        min: .9,
        value: 1,
        counter: 0.1
      },
      dataPin: [],
      accordion:{
        lvl1: '',
        lvl2: '',
      },
      pinActive: '',
    }
  },
  computed:{
    scaleUpDisabled(){
      return this.scale.value >= this.scale.max      
    },
    scaleDownDisabled(){
      return this.scale.value <= this.scale.min      
    }
  },
  mounted(){
    this.reCenter()
    this.dataPin = dataPin
  },
  methods:{
    dragMouseDown: function (event) {
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      this.dragDone = false
    },
    elementDrag: function (event) {
      if(!this.dragDone){
        const draggableElement = this.$refs.drag
        const clientX = event.clientX || event.touches[0].clientX
        const clientY = event.clientY || event.touches[0].clientY
        this.positions.movementX = this.positions.clientX - clientX
        this.positions.movementY = this.positions.clientY - clientY
        this.positions.clientX = clientX
        this.positions.clientY = clientY
        // set the element's new position:
        draggableElement.style.top = (draggableElement.offsetTop - this.positions.movementY) + 'px'
        draggableElement.style.left = (draggableElement.offsetLeft - this.positions.movementX) + 'px'
        console.log((draggableElement.offsetLeft - this.positions.movementX) + 'px');
      }
    },
    closeDragElement () {
      document.onmousemove = null
      this.dragDone = true
    },
    reCenter(){
      const el = this.$refs.drag
      const child = el.children
      const widthImg = el.clientWidth
      const heightImg = el.clientHeight
      const x = (window.outerWidth - widthImg) / 2
      const y = (heightImg - child[0].clientHeight) / 2

      console.log(child[0].clientHeight +'---'+ heightImg)
      el.style.top = y + 'px'
      el.style.left = x + 'px'
    },
    scaleUp(){
      const el = this.$refs.drag
      
      if(this.scale.max > this.scale.value){
        this.scale.value += this.scale.counter
        el.style.transform = `scale(${this.scale.value})`
      }
    },
    scaleDown(){
      const el = this.$refs.drag
      
      if(this.scale.min < this.scale.value){
        this.scale.value -= this.scale.counter
        el.style.transform = `scale(${this.scale.value})`
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end(el) {
      el.style.height = ''
    },
  }
}).mount('#maps-app')